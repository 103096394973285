export function password_check() {
    var myInput = document.getElementById('password-input');
    var letter = document.getElementById('letter');
    var char = document.getElementById('char');
    var number = document.getElementById('number');
    var length = document.getElementById('length');

    myInput.onkeyup = function () {
        // Validate lowercase letters
        var lowerCaseLetters = /[a-z]/g;
        var upperCaseLetters = /[A-Z]/g;
        if (myInput.value.match(lowerCaseLetters) && myInput.value.match(upperCaseLetters)) {
            letter.classList.remove('invalid');
            letter.classList.add('valid');
        } else {
            letter.classList.remove('valid');
            letter.classList.add('invalid');
        }

        // Validate spezial chars
        var upperCaseLetters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (myInput.value.match(upperCaseLetters)) {
            char.classList.remove('invalid');
            char.classList.add('valid');
        } else {
            char.classList.remove('valid');
            char.classList.add('invalid');
        }

        // Validate numbers
        var numbers = /[0-9]/g;
        if (myInput.value.match(numbers)) {
            number.classList.remove('invalid');
            number.classList.add('valid');
        } else {
            number.classList.remove('valid');
            number.classList.add('invalid');
        }

        // Validate length
        if (myInput.value.length >= 8) {
            length.classList.remove('invalid');
            length.classList.add('valid');
        } else {
            length.classList.remove('valid');
            length.classList.add('invalid');
        }
    };
}
