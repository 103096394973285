import { password_check } from './password_check';

const init = () => {
    $(document).on('click', '.openRegistration', function (e) {
        e.preventDefault();

        var target = $(this).attr('href');

        $.get(target, function (data) {
            $('#ajaxModal').html(data);
            $('#ajaxModal').modal('show');

            $('#password-input').strengthMeter('progressBar', {
                container: $('#password-strength-container'),
                hierarchy: {
                    0: 'progress-bar-danger',
                    25: 'progress-bar-warning',
                    75: 'progress-bar-success',
                },
            });
            password_check();
        });

        return false;
    });

    $(document).on('click', '.member-only', function (e) {
        if ($('#_authenticated').val() == '1') {
            return;
        }

        e.preventDefault();

        $('.loginModal').trigger('click');

        return false;
    });

    $(document).on('click', '.loginModal .dologin', function (e) {
        e.preventDefault();
        $('#errors-list').empty();

        let form = $('.loginModal form');

        $.post(form.attr('action'), form.serialize(), function (data) {
            window.location.reload();
        }).fail(function (response) {
            // handle error and show in html
            $('.alert').remove();
            var erroJson = JSON.parse(response.responseText);
            $('#errors-list').append("<p class='error'>" + erroJson.errors + '</p>');
        });
    });

    $(document).on('click', '.registerModal .doregister', function (e) {
        e.preventDefault();
        $('#errors-list').empty();

        let form = $('.registerModal form');

        $.ajax({
            url: form.attr('action'),
            data: form.serializeArray(),
            type: 'POST',
            success: function (response) {
                $('#errors-list').append("<p class='success'>" + response.message + '</p>');
                form.find('input').val('');

                form.fadeOut();
            },
            headers: {
                accept: 'text/json',
            },
            error: function (response) {
                // handle error and show in html
                var erroJson = JSON.parse(response.responseText).errors;

                for (var err in erroJson) {
                    for (var errstr of erroJson[err]) {
                        $('#errors-list').append("<p class='error'>" + errstr + '</p>');
                    }
                }

                let errors = response.responseJSON.errors;
                Object.keys(errors).forEach(function (key) {
                    $('#' + key + 'Input').addClass('is-invalid');
                    $('#' + key + 'Error')
                        .children('strong')
                        .text(errors[key][0]);
                });
            },
        });
    });

    $(document).on('click', '.forgotModal .doforgot', function (e) {
        e.preventDefault();
        $('#errors-list').empty();

        let form = $('.forgotModal form');

        $.ajax({
            url: form.attr('action'),
            data: form.serialize(),
            type: 'POST',
            success: function (response) {
                $('#errors-list').append("<p class='success'>" + response.message + '</p>');
                form.find('input[type=email]').val('');
            },
            headers: {
                accept: 'text/json',
            },
            error: function (response) {
                // handle error and show in html
                var erroJson = JSON.parse(response.responseText).errors;

                for (var err in erroJson) {
                    for (var errstr of erroJson[err]) {
                        $('#errors-list').append("<p class='error'>" + errstr + '</p>');
                    }
                }
            },
        });
    });
};

init();
